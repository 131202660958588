<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.50012 7L2.50012 12.2398C2.50012 12.2398 2.43762 15 5.15287 15L10.7709 15C10.7709 15 13.5 14.7603 13.5 12.1527L13.5 7"
      stroke="#46415A"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.71669 2.66666C2.23076 1.06666 3.57036 1 4.28705 1H11.2073C12.0342 1 13.5799 1.06666 14.1731 2.66666C14.2966 2.88888 14.5438 3.33333 14.9145 4.66666C15.3019 6.05983 14.2966 6.88889 13.4316 7H2.35928C1.60959 6.88889 0.738383 6.05983 1.0741 4.66666C1.39539 3.33333 1.60959 2.88888 1.71669 2.66666Z"
      stroke="#46415A"
    />
    <path d="M8 3V5" stroke="#46415A" stroke-linecap="round" />
    <path d="M11 3L11.5 5" stroke="#46415A" stroke-linecap="round" />
    <path d="M5 3L4.5 5" stroke="#46415A" stroke-linecap="round" />
    <path
      d="M10 15V12C10 10.8954 9.10457 10 8 10V10C6.89543 10 6 10.8954 6 12V15"
      stroke="#46415A"
    />
  </svg>
</template>
